li.reorder {
    display: flex;
    span {
        flex: 1;
        padding-left: .05rem;
    }
    i {
        flex: 0;
        color: $gold;
        &.icon-grip {
            margin-left: auto;
            color: rgba(255,255,255,.5);
            float: right;
        }
    }
}
.modal.modal-players {
    h3 {
        font-size: .18rem;
        text-transform: uppercase;
        font-family: 'primelight', sans-serif;
        color: $gold;
        margin: 1.25em 0 .75em 0;
    }
    .input-group {
        padding-bottom: .05rem;
        label {
            margin-bottom: .04rem;
        }
    }
    .chosen {
        .btn-group {
            margin-bottom: .15rem;
            .btn {
                font-size: .2rem;
                text-transform: none;
                &-default {
                    box-shadow: none;
                }
            }
        }

        .btn-full {
            margin-bottom: .15rem;
            .btn {
                font-size: .2rem;
                text-transform: none;
                &-default {
                    box-shadow: none;
                }
            }
        }
    }
    .player-list {
        list-style: none;
        padding-left: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        overflow:auto;
        margin-bottom: 0;
        left: 0;
        right: 0;
        ul {
            margin: 0;
            padding: 0;
        }
    }
    .modal-body {
        display: flex;
        flex-direction: column;
        .toppers, .players {
            display: flex;
            .cell {
                flex: 1;
            }
        }
        .toppers, .actions {
            flex: 0;
        }
        .players {
            flex: 1;
            .cell {
                position:relative;
            }
        }
        .cell:first-child {
            padding-right: .15rem;
            border-right: .01rem solid $gray;
        }
        .cell:last-child {
            margin-left: .15rem;
            
        }
        .actions {
            .cell {
                padding: .15rem 0 0 0;
                border: none;
                border-top: .01rem solid $gray;
                text-align:center;
            }
        }
    }
} 