.email-scores {
    position: absolute;
    width: 100%;
    top: .57rem;
    height: 100%;
    z-index: 903;

    .actions {
        padding-bottom: 0;
    }
}