#game-players {
    position:absolute;
    left: 0;
    top: 0;
    bottom:0;
    width: 2.77rem;
    overflow-y: scroll;
    padding: 0;
    transform:translateX(-2.75rem); 
    transition: 1s ease transform;
    display: flex;
    flex-direction: column;

    .game-name {
        font-size: .3rem;
        margin-bottom: .2rem;
        text-align: right;
        &.league-style {
            margin-right: .67rem;
        }
        &.first-to-21 {
            margin-right: .67rem;
        }
        &.bucket-list {
            margin-right: .9rem;
        }
        &.magic-number {
            margin-right: .63rem;
        }
        &.tic-tac-toe {
            margin-right: .9rem;
        }
        &.around-the-world {
            margin-right: .9rem;
        }
        &.open-throwing {
            margin-right: .63rem;
        }
    }
    .players {
        flex: 1;
    }
    .menu {
        flex: 0;
        width: 2.1rem;
        padding: 0 .15rem;
    }
    h3 {
        font-size: .3rem;
        color: $gold;
        margin-bottom: .1rem;
        width: 2.1rem;
        padding: 0 .15rem;
    }
    ul {
        list-style: none;
        margin: 0;
        padding:0;
        li {
            margin: 0;
            font-size: .25rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            position:relative;
            padding: .07rem 0;
            transition: color .15s ease;
            transition-delay: .0s;
        }
    }
    .players ul {
        list-style: none;
        margin: 0;
        padding:0;
        li {
            margin: 0;
            font-size: .25rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            position:relative;
            padding: .18rem .45rem .24rem .15rem;
            &.reduced-padding {
                padding: .12rem .45rem .12rem .15rem;
            }
            margin-bottom: -.27rem;
            &:last-child {
                margin-bottom: 0;
            }
            &:before {
                background: url(/images/player-select.svg) right top no-repeat;
                content: '';
                position: absolute;
                display: block;
                width: 100%;
                height: 1.7rem;
                box-sizing: content-box;
                left: -16%;
                top: 7%;
                z-index: -1;
                transform: scale(1.2, 1.4) translateX(-2.85rem);
                transition: .25s ease transform;
                will-change: transform;
            }
            
            &.selected {
                color: black;
                &:before {
                    transform: scale(1.4, 1.1) translateX(0);
                    margin-top: 3px;
                }

            }
        }
    }
    .team-1 {
        margin-bottom: .1rem;
    }
    .team-2 {
        h3 {
            border-top: .01rem solid $gray;
            padding-top: .15rem;
        }
    }

    
    &.multi-team {
        .selector {
            transform: translateY(.26rem);
        }
    }
    ul.game-menu {
        border-top: .01rem solid $gray;
        padding: 0;
        > li {
            padding: .1rem 0 .12rem 0;
        }
        li {
            font-size: .2rem;
            overflow: visible;
            white-space: normal;
            text-overflow: none;
            ul {
                @extend .trans-grad;
                position: absolute;
                right:calc(-100% - .15rem);
                bottom: 0;
                z-index: 5000;
                width: 100%;
                padding: .05rem 0;
                li {
                    padding: .05rem .075rem;
                }
            }
        }
    }
    .score-display {
        font-size: .18rem;
        margin-top: -0.14rem;
    }
}

#score-menu {
    .menu {
        // flex: 0;
        width: 2.1rem;
        padding: 0 .15rem;
        position: absolute;
        bottom: 0;
        z-index: 999;
    }

    ul.game-menu {
        // border-top: .01rem solid $gray;
        list-style: none;
        padding: 0;
        > li {
            padding: .1rem 0 .12rem 0;
        }
        li {
            font-size: .2rem;
            overflow: visible;
            white-space: normal;
            text-overflow: none;
            ul {
                @extend .trans-grad;
                position: absolute;
                // right:calc(-100% - -0.85rem);
                bottom: -29px;
                z-index: 5000;
                width: 260px;
                padding: .05rem 0;
                li {
                    padding: .02rem .075rem;
                }
            }
        }
    }
}