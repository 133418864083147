#game-setup { 
    visibility: hidden;
    transition:visibility .75s ease;
    position: absolute;
    left:0;
    top: 0;
    right: 0;
    bottom: 0;
    h2 {
        position: absolute;
        left: 50%;
        top: 20vh;
        transform:translateX(-50%);
        opacity:0;
        transition: opacity .75s ease;
    }
    .button-stack-single {
        // top: 35vh;
        transform:translate(-50%,80vh);
        transition: transform .75s ease;
        &.active {
            transform:translate(-50%,0);
        }
    }
    .button-select-21-style {
        position: absolute;
        //top: 22vh;
        left: 50%;
        transform:translate(-50%,130vh);
        transition: transform .75s ease;
        // width: 100vw;
        .btn {
            width: 3rem;
            display: block;
            float: left;
            margin: 0 .1rem;
        }
        &.active {
            // TODO: was (-50%, 30vh) but we need to account for new instructions button...
            transform:translate(-50%,26vh);
        }
    }
    #scoring {
        top: 80px;
        transform: translate(-50%,130vh);
        &.active {
            transform: translate(-50%,30vh);
            &.has-modifiers {
                // transform: translate(-50%,45vh) !important; have to account for new instructions button...
                transform: translate(-50%,40vh) !important;
            }
        }
    }
    #create-team-names {
        position: absolute;
        top: 30vh;
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
        .field-container {
            width: 35vw;
            position: absolute;
            &.team-name-1, &.team-name-2 {
                transition: transform .75s ease;
            }
            &.team-name-1 {
                right: 51.5%;
                transform:translateX(-50vw)
            }
            &.team-name-2 {
                left: 51.5%;
                transform:translateX(50vw);
            }
        }
        .btn-select-players {
            position: absolute;
            left: 50%;
            transform:translate(-50%,50vh);
            top: 25vh;
            transition: transform .75s ease;
        }
        .btn-players-back {
            position: absolute;
            left: 50%;
            transform:translate(-50%,50vh);
            top: 37vh;
            transition: transform .75s ease;
        }
        &.active {
            .team-name-1, .team-name-2 {
                transform:translateX(0);
            }
            .btn-select-players {
                transform: translate(-50%,-50%);
            }
            .btn-players-back {
                transform: translate(-50%,-50%);
            }
        }
    }
    &.active {
        visibility: visible;
        h2 {
            opacity: 1;
        }
    }
}