.game--tic-tac-toe {
    #grid-target {
        .point-labels {
            width: 81vw;

            .point-label.miss-right {
                margin-top: 140px;
            }
        }
    }
}
