#browse-games {
    visibility: hidden;
    transition:visibility 1s ease;
    .btn {
        margin: 0 .2rem;
    }
    .anim-1, .anim-3, .anim-5, .anim-8 {
        transform:translateX(calc(-100% - 3.25rem));
    }
    .anim-2, .anim-4, .anim-6, .anim-9 {
        transform:translateX(calc(100% + 3.25rem));
    }
    .anim-7 {
        transform:translateX(calc(100% + 5.25rem));
    }
    .anim-1, .anim-2, .anim-3, .anim-4, .anim-5, .anim-6, .anim-7, .anim-8, .anim-9 {
        will-change: transition;
        transition: .75s ease transform;
    }
    .anim-8, .anim-9 {
        transition-delay: 0s;
    }
    .anim-5, .anim-6 {
        transition-delay: .15s;
    }
    .anim-3, .anim-4 {
        transition-delay: .3s;
    }
    .anim-1, .anim-2 {
        transition-delay: .45s;
    }
    &.active {
        visibility: visible;
        .anim-1, .anim-2, .anim-3, .anim-4, .anim-5, .anim-6, .anim-7, .anim-8, .anim-9 {
            transform: translateX(0);
        }
    }
}