#wood {
    background: url(../images/wood.jpg) top right no-repeat;
    background-size: cover;
    position: absolute;
    right:0;
    top:0;
    width:100vw;
    height:100vh;
    transform-origin: top right;
}
#target {
    background: url(../images/target.svg) center 3vh no-repeat;
    background-size: auto 150%;
    position: absolute;
    right:0;
    top:0;
    width:100vw;
    height:100vh;
    transform:translateY(0);
    // mix-blend-mode: color-burn;
    // opacity: .35;
}
#target-feedback {
    position: absolute;
    right:0;
    top:28vh;
    width:100vw;
    height:100vh;
    transform:scale(1.666) translate(0);
    mix-blend-mode: color-burn;
}
.point-labels {
    position: absolute;
    right:0;
    top:0;
    width:100vw;
    height:100vh;
    transform:translateY(0);

    .point-label {
        position: absolute;
        color: #000;
        top: 78%;
        left: 50%;
        font-size: .75rem;
        transform:translate(-50%,-50%);
        opacity: 0;
        transition: opacity 1s ease;
        transition-delay: 1s;
    }
    .ring1 {
        left: calc(50% + 1.5rem);
    }
    .ring2 {
        left: calc(50% + 2.75rem);
    }
    .ring3 {
        left: calc(50% + 4rem);
    }
    .ring4 {
        left: calc(50% + 5.3rem);
    }
    .ks {
        font-size: .26rem;
        // top: 2.2rem;
        // left: calc(50% + 3.5rem);
        top: 2.4rem;
        left: calc(50% + 3.62rem);
    }
    .miss {
        left: calc(50% + 5.5rem);
        font-size: 0.375rem;
        top: 2rem;
    }
}
.action-ring {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    border-radius: 100%;
    box-shadow:  0 0 0 0 transparent;
    transition: box-shadow .25s ease;
    will-change: box-shadow;
    
    &.pop {
        box-shadow:  0 0 0 .668rem rgba(125,125,125,1);
        will-change: box-shadow;
    }
    &.action-miss.pop {
        box-shadow:  0 0 0 1.35rem rgba(125,125,125,1);
    }
}
.action-ring1 { width: 1.1rem; height: 1.1rem; }
.action-ring2 { width: 2.63rem; height: 2.63rem; }
.action-ring3 { width: 4.15rem; height: 4.15rem; }
.action-ring4 { width: 5.67rem; height: 5.67rem; }
.action-miss  { width: 7.2rem; height: 7.2rem;}

// Styles for flood style hit - way too choppy on tablet
// .action-bull {
//     height: .9rem;
//     width: .9rem;
//     background: $red;
//     border-radius: 100%;
//     position: absolute;
//     left: 50%;
//     top: 50%;
//     transform: translate(-50%,-50%);
//     transition: none;
//     opacity: 1;
//     &.pop {
//         transition: .75s ease transform, opacity .75s ease;
//         transform:translate(-50%,-50%) scale(15);
//         opacity: 0;
//         will-change: transition opacity;
//     }
// }

.action-bull {
    height: .9rem;
    width: .9rem;
    background: $red;
    border-radius: 100%;
    position: absolute;
    left: 50%;
    top: 50%;

    transition: none;
    opacity: 1;

    height: .6rem;
    width: .6rem;

    right: 3.925rem;
    top: 3.5rem;
    background: none;
    border: .03rem solid $red;
    box-sizing: border-box;
    transform-origin: center;
    opacity: 0;

    &.pop {
        transition: .75s ease transform, opacity .75s ease;
        // transform:translate(-50%,-50%) scale(15);
        transform: scale(20);
        opacity: 1;
        will-change: transition;
    }
}


.action-ks {
    height: 0.0000001rem;
    width: 0.0000001rem;
    background: #0330C6;
    border-radius: 100%;
    position: absolute;
    right: 32.1%;
    top: 21.2%;
    
    transition: none;
    opacity: 1;

    height: .6rem;
    width: .6rem;
    
    right: 3.925rem;
    top: 1.40rem;
    background: none;
    border: .03rem solid $blue;
    box-sizing: border-box;
    transform-origin: center;
    opacity: 0;
    &.pop {
        transition: .75s ease transform, opacity .75s ease;
        transform: scale(20);
        opacity: 1;
        will-change: transition;
    }
}

// TABLET RESOLUTION IS 1280X775 due to the status bar

.ring-miss {
    width: 16rem;
    height: 12rem;
    position: absolute;
    z-index: 900;
    top: 0;
    left: 20%;
    // background: white;
    border-radius: 50%;
    display: block;
    opacity: 0.5;
}

.ring-one {
    // width: 11.55rem;
    // height: 11.55rem;
    // position: absolute;
    // z-index: 901;
    // top: 2.9%;
    // left: 5.2%;
    // background: blue;
    // border-radius: 50%;
    // display: block;
    // opacity: 0.5;

    width: 12rem;
    height: 12rem;
    position: absolute;
    z-index: 901;
    top: 2.9%;
    left: 3.2%;
    // background: blue;
    border-radius: 50%;
    display: block;
    opacity: 0.5;
}

.ring-two {
    // width: 9.2rem;
    // height: 9.2rem;
    // position: absolute;
    // z-index: 902;
    // top: 18.8%;
    // left: 14.1%;
    // background: red;
    // border-radius: 50%;
    // display: block;
    // opacity: 0.5;

    width: 9.5rem;
    height: 9.5rem;
    position: absolute;
    z-index: 902;
    top: 18.8%;
    left: 12.8%;
    // background: red;
    border-radius: 50%;
    display: block;
    opacity: 0.5;
}

.ring-three {
    // width: 6.7rem;
    // height: 6.7rem;
    // position: absolute;
    // z-index: 903;
    // top: 34.7%;
    // left: 23.9%;
    // background: yellow;
    // border-radius: 50%;
    // display: block;
    // opacity: 0.5;

    width: 6.93rem;
    height: 7rem;
    position: absolute;
    z-index: 903;
    top: 34.7%;
    left: 22.97%;
    // background: yellow;
    border-radius: 50%;
    display: block;
    opacity: 0.5;
}

.ring-four {
    // width: 4.2rem;
    // height: 4.2rem;
    // position: absolute;
    // z-index: 904;
    // top: 50.56%;
    // left: 33.9%;
    // background: green;
    // border-radius: 50%;
    // display: block;
    // opacity: 0.5;

    width: 4.39rem;
    height: 4.39rem;
    position: absolute;
    z-index: 904;
    top: 50.56%;
    left: 32.9%;
    // background: green;
    border-radius: 50%;
    display: block;
    opacity: 0.5;
}

.ring-bullseye {
    // width: 1.8rem;
    // height: 1.8rem;
    // position: absolute;
    // z-index: 905;
    // top: 66.4%;
    // left: 43%;
    // background: red;
    // border-radius: 50%;
    // display: block;
    // opacity: 0.5;
    
    width: 1.85rem;
    height: 1.85rem;
    position: absolute;
    z-index: 905;
    top: 66.4%;
    left: 42.8%;
    // background: red;
    border-radius: 50%;
    display: block;
    opacity: 0.5;
}

.ring-blue {
    // width: .99rem;
    // height: .99rem;
    // position: absolute;
    // z-index: 906;
    // top: 23.64%;
    // left: 73.6%;
    // background: #fff;
    // border-radius: 50%;
    // display: block;
    // opacity: .5;

    // 1920X1200 || 1280X800
    width: 1.01rem;
    height: 1.01rem;
    position: absolute;
    z-index: 906;
    top: 23.71%;
    left: 74.4%;
    // background: white;
    border-radius: 50%;
    display: block;
    opacity: 0.5;
}
