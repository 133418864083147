#grid-target {
  position: absolute;
  right: 0;
  top: 0;
  width: calc(100vw - 2.4rem);
  height: 100vh;
  transform: translateY(0);

  .point-label.miss-right {
    transform: rotate(90deg);
    margin-left: -1.6rem;
    margin-top: 150px;
  }

  .point-label.miss-bottom {
    top: calc(100% - 75px);
    left: 50%;
  }

  .point-label.shuffle-points {
    color: $black;
    font-size: .25rem;
    top: calc(100% - 70px);
    position: absolute;
    left: 150px;
  }
}

.game-board {
  width: 600px;
  height: 600px;
  margin: 50px auto;
  color: #000;
  // border: 6px solid #000;
  border: none;
  display: grid;
  grid-template: repeat(3, 1fr) / repeat(3, 1fr);
  // transform: translateX(120px);
}

.game-score-board {
  width: 400px;
  height: 400px;
  color: #fff;
  border: 1px solid #fff;
  display: grid;
  grid-template: repeat(3, 1fr) / repeat(3, 1fr);
}

.box {
  border: 6px solid #000;
  font-weight: bold;
  font-size: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 901;
  position: relative;

  &:first-child {
    border-left: none;
    border-top: none;
  }

  &:nth-child(2) {
    border-top: none;
  }

  &:nth-child(3) {
    border-top: none;
    border-right: none;
  }

  &:nth-child(4) {
    border-left: none;
  }

  &:nth-child(6) {
    border-right: none;
  }

  &:nth-child(7) {
    border-left: none;
    border-bottom: none;
  }

  &:nth-child(8) {
    border-bottom: none;
  }

  &:nth-child(9) {
    border-right: none;
    border-bottom: none;
  }
}

.box-score {
  border: 1px solid #fff;
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 901;
  position: relative;
}

@mixin bg-position {
  content: " ";
  background-size: contain;
  position: absolute;
  inset: 12px;
  background-position: center;
  background-repeat: no-repeat;
}

@mixin cross($bg_color) {
  @include bg-position;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='319.967' height='320.834' viewBox='0 0 319.967 320.834'%3E%3Cpath id='xmark' d='M312.1,375a24,24,0,1,1-33.94,33.94L160,289.9,41,408.9A24,24,0,0,1,7.06,374.96L126.1,256,7.027,136.1a24,24,0,0,1,33.94-33.94L160,222.1l119-119a24,24,0,1,1,33.94,33.94L193.9,256Z' transform='translate(0 -95.133)' fill='" + $bg_color + "'/%3E%3C/svg%3E%0A");
}

@mixin circle($bg_color) {
  @include bg-position;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='512' height='512' viewBox='0 0 512 512'%3E%3Cpath id='circle' d='M512,256c0,141.4-114.6,256-256,256S0,397.4,0,256,114.6,0,256,0,512,114.6,512,256ZM256,48C141.1,48,48,141.1,48,256s93.1,208,208,208,208-93.1,208-208S370.9,48,256,48Z' fill='" + $bg_color + "'/%3E%3C/svg%3E%0A");
}

.cross:after {
  @include cross('%23c60303');
  color: $red;
}

.cross-score:after {
  color: #fff;
  @include cross('%23c60303');
}

.circle:after {
  @include circle('%23c60303');
  color: $red;
}

.circle-score:after {
  @include circle('%23fff');
  color: #fff;
}

.action-square {
  transition: box-shadow .25s ease;
  will-change: box-shadow;

  &.pop {
    box-shadow: 0 0 0 .668rem rgba(125, 125, 125, 1);
    will-change: box-shadow;
    z-index: 902;
  }
}

.tic-tac-toe-scoreboard {
  margin: 100px auto;
}

.throw-count-table {
  float: left;
  margin-right: 100px;
  margin-top: 100px;
}

.blue-ball {
  width: 100px;
  height: 100px;
  border: $blue 15px solid;
  border-radius: 50%;
}